import * as React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import { css } from "@emotion/react"
import { langs } from "../../prismic-configuration.js"

import Layout from "../components/layout"
import Seo from "../components/seo"
import CardBlogPost from "../components/cardBlogPost"

import iconArrowRight from "../images/icons/arrow_right.svg"

const NextPage = ({ total, current, pathLang, webview }) => {
  if (current < total) {
    return (
      <Link
        to={pathLang + (webview ? "/webview" : "") + "/blog/" + (current + 1)}
        className="ml-5 font-montserrat font-extrabold rounded-md bg-pocket-yellow inline-block md:ml-1"
      >
        <img src={iconArrowRight} className="w-12 h-12 md:w-5 md:h-5 " alt="" />
      </Link>
    )
  } else {
    return false
  }
}
const PrevPage = ({ current, pathLang, webview }) => {
  if (current > 1) {
    return (
      <Link
        to={
          pathLang +
          (webview ? "/webview" : "") +
          `/blog/` +
          (current === 2 ? "" : current - 1)
        }
        className="mr-5 font-montserrat font-extrabold rounded-md bg-pocket-yellow inline-block md:mr-1"
      >
        <img
          src={iconArrowRight}
          className="rotate-180 w-12 h-12 md:w-5 md:h-5"
          alt=""
        />
      </Link>
    )
  } else {
    return false
  }
}

const BlogPage = ({ data, pageContext }) => {
  if (!data) return null

  const pathLang = pageContext.lang === "en-us" ? "" : "/fr-fr"
  const postsWebsite = data.allPrismicBlogPost.edges.filter(
    p => p.node.data.displayed_on !== "webview only"
  )
  const postsWebview = data.allPrismicBlogPost.edges.filter(
    p => p.node.data.displayed_on !== "website only"
  )
  const posts = pageContext.webview ? postsWebview : postsWebsite
  return (
    <Layout
      lang={pageContext.lang}
      globals={data.prismicGlobals}
      webview={pageContext.webview}
    >
      <div
        className={`container px-8  ${
          !pageContext.webview ? "mb-28" : "pb-12"
        } mx-auto lg:px-32 `}
      >
        {!pageContext.webview && (
          <div
            className="text-3xl mb-8 font-extrabold lg:text-5xl "
            css={cssblueTitle}
            dangerouslySetInnerHTML={{
              __html: data.prismicBlogListPage.data.title.html,
            }}
          ></div>
        )}
        <div className="flex flex-col flex-wrap md:flex-row">
          {posts.map(post => (
            <div key={post.node.uid} className=" lg:w-1/2 lg:px-5 xl:w-1/3">
              <CardBlogPost
                post={post.node}
                globals={data.prismicGlobals}
                webview={pageContext.webview}
              />
            </div>
          ))}
        </div>
        <div className="flex items-center justify-center mx-auto text-center">
          <PrevPage
            current={pageContext.currentPage}
            pathLang={pathLang}
            webview={pageContext.webview}
          />
          {Array.from({ length: pageContext.nbrPages }).map((page, i) => {
            if (i + 1 === pageContext.currentPage) {
              return (
                <span
                  key={i}
                  className="hidden text-pocket-yellow font-extrabold px-2 md:block"
                >
                  {pageContext.currentPage}
                </span>
              )
            } else {
              return (
                <Link
                  key={i}
                  to={
                    pathLang +
                    (pageContext.webview ? `/webview` : "") +
                    `/blog/` +
                    (i + 1 === 1 ? "" : i + 1)
                  }
                  className="hidden font-montserrat font-extrabold text-neutral-500 px-2 md:block"
                >
                  {i + 1}
                </Link>
              )
            }
          })}
          <NextPage
            current={pageContext.currentPage}
            total={pageContext.nbrPages}
            pathLang={pathLang}
            webview={pageContext.webview}
          />
        </div>
      </div>
    </Layout>
  )
}

const cssblueTitle = css`
  & strong {
    color: var(--pocket-blue);
  }
`

export const query = graphql`
  query blogPageQuery(
    $lang: String
    $graphQl_displayed_on_NE: String
    $limit: Int
    $skip: Int
  ) {
    prismicBlogListPage(lang: { eq: $lang }) {
      data {
        seo_title
        seo_description
        seo_og_image {
          url
        }
        title {
          html
          text
        }
      }
    }

    allPrismicBlogPost(
      filter: {
        lang: { eq: $lang }
        data: {
          publishing_order: { gt: "1" }
          displayed_on: { ne: $graphQl_displayed_on_NE }
        }
      }
      limit: $limit
      skip: $skip
      sort: { fields: data___publishing_order, order: DESC }
    ) {
      totalCount
      edges {
        node {
          uid
          lang
          url
          data {
            type
            displayed_on
            external_link
            title {
              text
              html
            }
            subtitle {
              text
              html
            }
            card_image {
              gatsbyImageData
              alt
            }
            cover {
              gatsbyImageData
              alt
            }
            structured_text {
              text
            }
            category {
              document {
                ... on PrismicArticleCategory {
                  data {
                    category_name
                    category_color
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicGlobals(lang: { eq: $lang }) {
      data {
        follow_us
        read_more
        view_all
        footer_title
        footer_subtitle
        copyright
        store {
          link
          store_id
        }
        social {
          link
          social_id
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({ data, pageContext }) => {
  const { siteUrl } = data.site.siteMetadata

  return (
    <Seo
      title={
        data.prismicBlogListPage.data.seo_title ||
        data.prismicBlogListPage.data.title.text
      }
      description={data.prismicBlogListPage.data.seo_description}
    >
      {pageContext.webview && <meta name="robots" content="noindex" />}
      <meta
        property="og:image"
        content={data.prismicBlogListPage.data.seo_og_image?.url}
      />
      {langs.map((lang, i) => (
        <link
          rel="alternate"
          key={i}
          hrefLang={lang.slice(0, 2)}
          href={i === 0 ? siteUrl + "/blog" : siteUrl + "/" + lang + "/blog"}
        />
      ))}
    </Seo>
  )
}

export default BlogPage
